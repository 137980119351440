import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import navations from "./navigation";

const FooterNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="fixed bottom-0 left-0 flex w-full justify-between bg-white rounded-t-3xl px-4 py-4 shadow-2xl">
      {navations.map((navigation) => {
        const Icon = navigation.icon;
        return (
          <div
            onClick={() => navigate(navigation.link)}
            className="cursor-pointer"
            key={navigation.id}
          >
            <Icon
              className={`h-7 ${
                location.pathname.includes(navigation.link) &&
                "text-primaryColor"
              }`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FooterNavigation;
