import { lazy } from "react";

const LandingPage = lazy(() => import("../pages/landingPage/LandingPage"));
const Home = lazy(() => import("../pages/homePage/HomePage"));
const Info = lazy(() => import("../pages/infoPage/InfoPage"));
const Location = lazy(() => import("../pages/locationPage/LocationPage"));
const CardInfo = lazy(() => import("../pages/cardInfo/CardInfo"));
const CategoryDetail = lazy(() =>
  import("../pages/categoryDetail/CategoryDetail")
);
const InfoDetail = lazy(() => import("../pages/infoDetail/InfoDetail"))

const appRoutes = [
  {
    id: "landingPage",
    path: "/",
    exact: true,
    component: LandingPage,
    meta: {
      appLayout: false,
      footer: false,
    },
  },
  {
    id: "homePage",
    path: "/home",
    exact: true,
    component: Home,
    meta: {
      appLayout: true,
      footer: true,
    },
  },
  {
    id: "infoPage",
    path: "/info",
    exact: true,
    component: Info,
    meta: {
      appLayout: true,
      footer: true,
    },
  },
  {
    id: "infoDetail",
    path: "/info/:infoId",
    exact: true,
    component: InfoDetail,
    meta: {
      appLayout: true,
      footer: true,
    },
  },
  {
    id: "locationPage",
    path: "/location",
    exact: true,
    component: Location,
    meta: {
      appLayout: true,
      footer: true,
    },
  },
  {
    id: "cardInfo",
    path: "/:cardType/:cardId",
    exact: true,
    component: CardInfo,
    meta: {
      appLayout: true,
      footer: true,
    },
  },
  {
    id: "categoryInfo",
    path: "/insights/:title",
    exact: true,
    component: CategoryDetail,
    meta: {
      appLayout: true,
      footer: true,
    },
  },
];

export default appRoutes;
