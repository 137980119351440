import {
  HomeIcon,
  InformationCircleIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";

const navations = [
  {
    id: "home",
    link: "/home",
    icon: HomeIcon,
  },
  { id: "info", link: "/info", icon: InformationCircleIcon },
  {
    id: "location",
    link: "/location",
    icon: MapPinIcon,
  },
];

export default navations;
