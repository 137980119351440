import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FooterNavigation from "../components/footerNavigation/FooterNavigation";
import appRoutes from "./appRoutes";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {appRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              exact={route.exact}
              element={
                <Suspense
                  fallback={
                    <div className="flex w-full h-[100vh] items-center justify-center">
                      <img
                        src="/logo/logo.png"
                        alt="Logo"
                        className="w-[150px]"
                      />
                    </div>
                  }
                >
                  <div className={route.meta.appLayout && "p-4"}>
                    <div className={route.meta.footer && "pb-16"}>
                      <route.component />
                    </div>
                    {route.meta.footer && (
                      <div>
                        <FooterNavigation />
                      </div>
                    )}
                  </div>
                </Suspense>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
